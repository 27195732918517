export default props => {
    return (
      <>
        <header className="bg-white shadow text-center">
          <div className="mx-auto max-w-7xl py-6 px-4 sm:px-6 lg:px-8">
            <h1 className="text-3xl font-bold leading-tight tracking-tight text-gray-900">{props.title}</h1>
          </div>
        </header>
      </>
    );
  }