import React, { useState, useEffect } from 'react';
import { CheckCircleIcon, XCircleIcon, ClockIcon, DocumentIcon } from '@heroicons/react/24/solid';
import { VerificationSession, Welcome } from 'cleared-verification-client';
import 'cleared-verification-client/dist/styles/no-tailwind.css';
import Http from '../../../Utils/Http';
import Notifier from '../../../Utils/Notifier';
import Header from '../../../Components/Header';

const VerificationCentre = () => {
    const [authToken, setAuthToken] = useState(null);
    const [customerId, setCustomerId] = useState(null);
    const [emailAddress, setEmailAddress] = useState(null);
    const [loaded, setLoaded] = useState(false);

    useEffect(() => {
        getUserToken();
    }, []);

    const getUserToken = async () => {
        let body = {};
        const response = await Http.request({ uri: `/session/cleared-auth-token`, body });
        if (response.notification) {
            Notifier(response.notification);
        }
        if (response.result === 'success') {
            setAuthToken(response.body.token);
            setCustomerId(response.body.customerId);
            setEmailAddress(response.body.emailAddress);
        }
        setLoaded(true);
    }

    return (
        <>
        <Header title={"Verification Centre"} />
        <div className="p-10 w-5/6 md:w-2/3 mx-auto">
            <h2 className="mt-5 text-lg md:text-xl leading-6 text-gray-600 text-center lg:text-left">Verify identity, address and income</h2>
            <p className="mt-5 mb-5 text-gray-800 text-center lg:text-left">
                Our Verification Centre is a convenient online tool that you can use to stay KYC-compliant. Manage your ID verification here, as well as address and income verification.
            </p>
            <hr />
            {authToken && customerId && <>
                <div className='mt-5 w-full'>
                    <VerificationSession authToken={authToken} services={['identity', 'address']} customerReferenceId={customerId} customerData={{emailAddress}} />
                </div>
            </>}
            {loaded && !authToken &&
                <div className='mt-5 w-full'>
                    <Welcome customerReferenceId={customerId} customerData={{emailAddress}} />
                </div>
            }
        </div>
        </>
    );
};

export default VerificationCentre;