import Header from "../../../Components/Header";
import { useEffect, useState } from "react";
import { HomeIcon, MagnifyingGlassIcon, PlusIcon } from '@heroicons/react/20/solid'
import { Bars3Icon, BellIcon, PhoneIcon, XMarkIcon } from '@heroicons/react/24/outline'
import { Disclosure, Menu, RadioGroup, Transition } from '@headlessui/react'
import { Input } from "../../../Utils/Template";
import Http from "../../../Utils/Http";
import Notifier from "../../../Utils/Notifier";

export default function Points() {

    const textLogo = <span className="myrfs-highlight">MY<span className="myrfs-highlight-rfs">RFS</span></span>;

    return (
        <div className="text-poppins">
            <div className="text-md md:text-xl text-gray-900 mx-auto md:w-4/5 px-10 md:p-0 mt-10 mb-10 text-poppins font-poppins">
                <div>
                    <div className="myrfs-container">
                        <h1 className="myrfs-title text-lg md:text-3xl">GET TO KNOW YOUR {textLogo} REWARDS BENEFITS</h1>
                        <p className="myrfs-description">
                            <span className="orange-highlight">You deserve to be rewarded for your loyalty.</span> As a Regions Financial Services’ customer you can earn points at each Elite Tier, the higher your tier the more points you earn. You earn points every time you make a payment.
                        </p>
                        <h2 className="myrfs-subtitle text-lg md:text-3xl mt-10">WHAT IS {textLogo} ELITE STATUS</h2>
                        <p className="myrfs-description">
                            Our MYRFS Status program consists of five tiers, each offering a unique set of benefits. You can see your tier on the MYRFS dashboard under your name. The more loans you receive and the more points you earn, the greater your opportunities to elevate your MYRFS Status.
                        </p>
                        <p className="myrfs-description">
                            There are 5 statuses with each status you are rewarded with even more benefits. See the benefit schedule below.
                        </p>
                    </div>

                    <div className="myrfs-points-container">
                        <h1 className="myrfs-points-title text-lg md:text-3xl">HOW DO I EARN <span className="myrfs-points-highlight">{textLogo} POINTS</span></h1>

                        <p className="myrfs-points-description">
                            <span className="myrfs-points-subtitle blue-highlight font-bold text-md md:text-2xl">Earn Points When You Pay.</span> As a Regions Financial Services’ Client, you’ll earn points each time you make a payment on your loan. Your points are earned based on the total interest and fees associated with each payment. You can earn multiple times the points based on your status tier (see the schedule below).
                        </p>
                        <p className="redeem-points-description">
                            On your MYRFS dashboard you will be able to see all the points that you have earned.
                        </p>
                    </div>

                    <div className="redeem-points-container">

                        <h1 className="redeem-points-title text-lg md:text-3xl">HOW DO I REDEEM MY POINTS</h1>
                        <p className="myrfs-points-subtitle blue-highlight font-bold text-md md:text-2xl">Points can be used to make a payment on your loan.</p>
                    </div>


                    <div class="md:flex">
                        <div class="w-full md:w-2/5">
                            <img src="/assets/images/redemption-of-points.png" class="fit-box-cover" />
                        </div>
                        <div class="w-full md:w-3/5 md:pl-5 my-5 md:my-0">
                            <p class="redeem-points-description">
                                A minimum of 50,000 points is required for points redemption.
                            </p>
                            <p class="redeem-points-description">
                                Once you have earned 50,000 points or more the “Pay with Points” option will become available on your active loan.
                            </p>
                            <p class="redeem-points-description">
                                The cash redemption value of using your points to make a payment is 1 percent of the point value. Points are redeemable in 50,000 increments.
                            </p>
                            <p class="blue-highlight">
                                *Note: Points are earned <span class="font-bold">only</span> on a loan that is current at the time of the loan payment. No points are earned when a loan is in arrears.
                            </p>
                        </div>
                    </div>




                    <h1 className="myrfs-points-title text-lg md:text-3xl mt-10">
                        {textLogo} STATUS AND REWARD BENEFITS
                    </h1>
                    <p className="redeem-points-description">Get the most out of your Elite Benefits. Earn more benefits at each tier.</p>


                    <div className="overflow-x-auto">
                        <table className="min-w-full bg-white">
                            <thead>
                                <tr>
                                    <th className="p-4 benefits-col text-white">BENEFITS</th>
                                    <th className="p-4 elite-col text-white">ELITE*</th>
                                    <th className="p-4 silver-elite-col text-white">SILVER ELITE*</th>
                                    <th className="p-4 gold-elite-col text-white">GOLD ELITE*</th>
                                    <th className="p-4 platinum-elite-col text-white">PLATINUM ELITE*</th>
                                    <th className="p-4 diamond-elite-col text-white">DIAMOND ELITE*</th>
                                </tr>
                            </thead>
                            <tbody>
                                <tr>
                                    <td className="p-4">Tier</td>
                                    <td className="p-4 elite-cell">1 Loan</td>
                                    <td className="p-4 silver-elite-cell">3 Loans or <br />1 Million Points</td>
                                    <td className="p-4 gold-elite-cell">5 Loans or <br />2 Million Points</td>
                                    <td className="p-4 platinum-elite-cell">7 Loans or <br />5 Million Points</td>
                                    <td className="p-4 diamond-elite-cell">10 Loans or <br />10 Million Points</td>
                                </tr>
                                <tr>
                                    <td className="p-4">Loan Limits</td>
                                    <td className="p-4 elite-cell">Up to <br />$1 Million</td>
                                    <td className="p-4 silver-elite-cell">Up to <br />$2 Millions</td>
                                    <td className="p-4 gold-elite-cell">Up to <br />$3 Million</td>
                                    <td className="p-4 platinum-elite-cell">Up to <br />$5 Million</td>
                                    <td className="p-4 diamond-elite-cell">Up to <br />$10 Million</td>
                                </tr>
                                <tr>
                                    <td className="p-4">Loan Repayment Term</td>
                                    <td className="p-4 elite-cell">Up to <br />3 Years</td>
                                    <td className="p-4 silver-elite-cell">Up to <br />3 Years</td>
                                    <td className="p-4 gold-elite-cell">Up to <br />3 Years</td>
                                    <td className="p-4 platinum-elite-cell">Up to <br />5 Years</td>
                                    <td className="p-4 diamond-elite-cell">Up to <br />7 Years</td>
                                </tr>
                                <tr>
                                    <td className="p-4">Payment Grace Period</td>
                                    <td className="p-4 elite-cell checkmark-cell">✔</td>
                                    <td className="p-4 silver-elite-cell checkmark-cell">✔</td>
                                    <td className="p-4 gold-elite-cell checkmark-cell">✔</td>
                                    <td className="p-4 platinum-elite-cell checkmark-cell">✔</td>
                                    <td className="p-4 diamond-elite-cell checkmark-cell">✔</td>
                                </tr>
                                <tr>
                                    <td className="p-4">Early Payoff Savings</td>
                                    <td className="p-4 elite-cell checkmark-cell">✔</td>
                                    <td className="p-4 silver-elite-cell checkmark-cell">✔</td>
                                    <td className="p-4 gold-elite-cell checkmark-cell">✔</td>
                                    <td className="p-4 platinum-elite-cell checkmark-cell">✔</td>
                                    <td className="p-4 diamond-elite-cell checkmark-cell">✔</td>
                                </tr>
                                <tr>
                                    <td className="p-4">Interest Rate Reduction</td>
                                    <td className="p-4 elite-cell"></td>
                                    <td className="p-4 silver-elite-cell checkmark-cell">✔</td>
                                    <td className="p-4 gold-elite-cell checkmark-cell">✔</td>
                                    <td className="p-4 platinum-elite-cell checkmark-cell">✔</td>
                                    <td className="p-4 diamond-elite-cell checkmark-cell">✔</td>
                                </tr>
                                <tr>
                                    <td className="p-4">Payment Deferral</td>
                                    <td className="p-4 elite-cell"></td>
                                    <td className="p-4 silver-elite-cell"></td>
                                    <td className="p-4 gold-elite-cell checkmark-cell">✔</td>
                                    <td className="p-4 platinum-elite-cell checkmark-cell">✔</td>
                                    <td className="p-4 diamond-elite-cell checkmark-cell">✔</td>
                                </tr>
                                <tr>
                                    <td className="p-4">Penalty Waiver</td>
                                    <td className="p-4 elite-cell"></td>
                                    <td className="p-4 silver-elite-cell"></td>
                                    <td className="p-4 gold-elite-cell"></td>
                                    <td className="p-4 platinum-elite-cell checkmark-cell">✔</td>
                                    <td className="p-4 diamond-elite-cell checkmark-cell">✔</td>
                                </tr>
                                <tr>
                                    <td className="p-4">Priority Disbursements</td>
                                    <td className="p-4 elite-cell"></td>
                                    <td className="p-4 silver-elite-cell"></td>
                                    <td className="p-4 gold-elite-cell"></td>
                                    <td className="p-4 platinum-elite-cell checkmark-cell">✔</td>
                                    <td className="p-4 diamond-elite-cell checkmark-cell">✔</td>
                                </tr>
                                <tr>
                                    <td className="p-4">Payment Reward Points</td>
                                    <td className="p-4 elite-cell">1X</td>
                                    <td className="p-4 silver-elite-cell">1X</td>
                                    <td className="p-4 gold-elite-cell">1X</td>
                                    <td className="p-4 platinum-elite-cell">2X</td>
                                    <td className="p-4 diamond-elite-cell">3X</td>
                                </tr>
                                <tr>
                                    <td className="p-4">Partner Benefits</td>
                                    <td className="p-4 elite-cell checkmark-cell">✔</td>
                                    <td className="p-4 silver-elite-cell checkmark-cell">✔</td>
                                    <td className="p-4 gold-elite-cell checkmark-cell">✔</td>
                                    <td className="p-4 platinum-elite-cell checkmark-cell">✔</td>
                                    <td className="p-4 diamond-elite-cell checkmark-cell">✔</td>
                                </tr>
                                <tr>
                                    <td className="p-4">Access to QuikCash</td>
                                    <td className="p-4 elite-cell"></td>
                                    <td className="p-4 silver-elite-cell">Up to $75,000</td>
                                    <td className="p-4 gold-elite-cell">Up to $85,000</td>
                                    <td className="p-4 platinum-elite-cell">Up to $85,000</td>
                                    <td className="p-4 diamond-elite-cell">Up to $100,000</td>
                                </tr>
                            </tbody>
                        </table>
                    </div>
                </div>
            </div>
        </div>
    );

}